<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Registration')"
      icon="mdi-code-block-tags"
  >

    <ws-accordion
        :items="navigationItems"
        class="mb-3"
    >
      <template #item.link>

        <div class="pa-6">
          <v-row no-gutters>
            <v-col
                v-if="!$store.state.business.selectedBusiness.disable_public"
                cols="12" md="6"
                :class="[{'pr-3' : !SM}]"
            >
              <div class="d-flex align-center">
                <ws-text-field
                    label="Посилання на лендінг"
                    :value="courseLandingPage"
                    readonly
                    class="pointer"
                    width="100%"
                />
                <v-btn
                    @click="CLIPBOARD(courseLandingPage)"
                    :color="wsACCENT"
                    class="mt-6 ml-3"
                    icon
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>

            </v-col>

            <v-col
                v-if="!$store.state.business.selectedBusiness.disable_public"
                cols="12" md="6"
                :class="[{'pl-3' : !SM}, {'pt-3' : SM}, {'pt-9' : !SM}]"
            >
              <h5 class="font-weight-regular wsDARKLIGHT ">
<!--                Опис посилання на лендінг-->
              </h5>
            </v-col>

            <v-col
                :class="[{'pr-3' : !SM}]"
                cols="12" md="6"
            >
              <div class="d-flex align-center">
                <ws-text-field
                    label="Пряме посилання на реєстрацію"
                    :value="businessPublicLink(`course/enroll/${course.uuid}` , true)"
                    readonly
                    class="mt-5 pointer"
                    width="100%"
                />
                <v-btn
                    @click="CLIPBOARD(businessPublicLink(`course/enroll/${course.uuid}` , true))"
                    :color="wsACCENT"
                    class="mt-12 ml-3"
                    icon
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" md="6"
                   :class="[{'pl-3' : !SM}, {'pt-8' : !SM}]"
            >
              <h5 class="font-weight-regular wsDARKLIGHT mt-6">
<!--                Опис прямого посилання на реєстрацію на курс-->
              </h5>

            </v-col>

          </v-row>

        </div>

      </template>

      <template #item.button>
        <div class="pa-6">
          <v-sheet
              :color="wsLIGHTCARD"
              style="border : 1px solid var(--wsBACKGROUND) !important"
              class="pa-3 wsRoundedSemi"
          >
            <h4 class="font-weight-regular wsACCENT">
              Якщо ви вже маєте сайт вашої школи, сгенеруйте код кнопки із формою на реєстрацію та вставте на свій сайт
            </h4>
          </v-sheet>

          <v-row class="mt-10 mb-10">

            <v-col>
              <ws-text-field v-model="buttonData.text" label="Текст кнопки"></ws-text-field>
            </v-col>

<!--            <v-col>-->
<!--              <ws-button :label-raw="buttonData.text" class="mt-7"></ws-button>-->
<!--            </v-col>-->

          </v-row>


          <ws-text-field
              @click="CLIPBOARD(scriptHtml)"
              readonly
              label="Вставте даний код в HEAD вашого сайту або будь де в BODY"
              :value="scriptHtml"
          />
          <ws-text-field
              @click="CLIPBOARD(buttonHtml)"
              label="Вставте даний код в місці де ви хочете вставити кнопку "
              :value="buttonHtml"
              area
              readonly
              class="mt-5"
          />

          <v-sheet
              :color="wsLIGHTCARD"
              style="border : 1px solid var(--wsBACKGROUND) !important"
              class="pa-3 mt-5 mb-5 wsRoundedSemi"
          >
            <h4 class="font-weight-regular wsACCENT">
              Ви можете зробити кнопкою реєсрації/покупки будь який <span class="font-weight-bold">html</span> елемент, для цього потрібно виконати 2 дії над потрібним елементом
              <br><br>Додати клас <span class="font-weight-bold">ws-embed-button-enroll</span>
              <br>Додати параметр із метаданими форми реєстрації <span class="font-weight-bold">data-ws-enroll</span>
            </h4>
          </v-sheet>


        </div>
      </template>

      <template #item.form>
        <v-row no-gutters>
          <v-col cols="6">
            <h5>Form</h5>
            <ws-switch placeholder="Спрощена реєстрація на курс "></ws-switch>
          </v-col>
          <v-col>
            <h5>Як реэстарцуцук</h5>
          </v-col>

          <v-col cols="6">
            <h5>Oбов'язкові поля </h5>
          </v-col>
          <v-col>
            <h5>Які поля є обовʼязковими для реєстарції </h5>
          </v-col>

          <v-col cols="6">
            <h5>Додаткові поля </h5>
            <ws-button label="Додати поле" />
          </v-col>
          <v-col>
            <h5>Як реэструватись</h5>
          </v-col>
        </v-row>









      </template>

    </ws-accordion>

  </sub-page>
</template>

<script>

import languagesList from "@/mixins/languagesList";
import {mapMutations, mapState} from "vuex";

export default {
  name: "courseSettings",
  props : ['uuid'],
  mixins : [languagesList],
  data() {
    return {
      buttonData : {}
    }
  },
  computed : {
    ...mapState('courses', [ 'course' ]),

    buttonHtml() {

      let data = {
        course_id : this.course.uuid,
        business : this.$store.state.business.selectedBusiness.alias
      }

      const dateEncoded = btoa(JSON.stringify(data))

      return `<a data-ws-enroll="${dateEncoded}" class="ws-embed-button-enroll">${this.buttonData.text}</a>`;
    },
    scriptHtml() {
      const s = '/script'
      return `<script src="${process.env.VUE_APP_API_URL}button_enroll.js"><${s}>`
    },

    courseLandingPage() {
      if (this.course.page) {
        return this.businessPublicLink(`page/${this.course.page}` , true)
      }
      return this.businessPublicLink(`course/${this.course.uuid}` , true)

    },
    navigationItems() {
      return [
        { name : this.$t('Link') ,
          subtitle : this.$t('RegistrationLinkDescription') ,
          expand : true,
          expanded : true,
          value: 'link' ,
          icon: 'mdi-link'
        },
        { name : this.$t('button') ,
          subtitle : this.$t('RegistrationLinkDescription') ,
          expand : true,
          expanded : true,
          value: 'button' ,
          icon: 'mdi-button-pointer'
        },
        // { name : this.$t('Form') ,
        //   subtitle : this.$t('RegistrationLinkDescription') ,
        //   expand : true,
        //   expanded : true,
        //   value: 'form' ,
        //   icon: 'mdi-form-select'
        // },
        // { name : this.$t('Merchant') ,
        //   subtitle : this.$t('RegistrationLinkDescription') ,
        //   expand : true,
        //   expanded : true,
        //   value: 'merchant' ,
        //   icon: 'mdi-credit-card-check-outline'
        // }
      ]
    }
  },
  methods : {
    ...mapMutations('courses', ['INIT_PAGE']),

    // technical

  },
  beforeMount() {
    this.buttonData = {
      text : this.$t('PurchaseCourse')
    }
  }
}
</script>

<style scoped>

</style>