<template>
  <dash-page-new
      :title="entity.name"
      icon="mdi-auto-fix"
  >
    <div class="d-flex flex-row fill-height py-6">

      <div class="flex-grow-1 fill-height" >
        <div class="d-flex justify-center" >


          <div style="width: 400px">
            <automation-chip
                @select="selectedChip = 'trigger'"
                :is-selected="selectedChip === 'trigger'"
                is-trigger
                class="mb-15"
            />

            <automation-chip
                v-for="(action,index) in actions" :key="index"
                @select="selectAction(action)"
                :is-selected="selectedChip === action.uuid"
                :item="action"
            />

            <div class="d-flex justify-center">
              <ws-button @click="addAction" class="mx-auto" label="Add"></ws-button>
            </div>

          </div>

        </div>
      </div>

      <v-sheet
          width="400" min-width="400" class="fill-height wsRoundedHalf overflow-y-auto pa-6"
          style="border : 1px solid var(--wsBACKGROUND) !important"
      >
        <div v-if="!selectedAction.type">
          <div class="d-flex justify-space-between align-center mb-5">
            <h3 class="wsDARKER ">
              {{ selectedChip === 'trigger' ? 'Select Trigger Event' : 'Select Action' }}
            </h3>
            <v-btn icon :color="wsACCENT">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>



          <ws-text-field
              v-model="search"
              :placeholder="$t('Search')"
              class="mb-5"
              icon="mdi-magnify"
              clearable
          />

          <h4 class="wsDARKER my-4">Group/Category name</h4>
          <automation-select-chip
              v-for="(actionType,index)  in selectedChip === 'trigger' ? triggerTypes : actionTypes" :key="index + 'action_type'"
              @select="changeActionType(selectedAction , actionType)" :label="actionType.name"
              :item="actionType"
          />





        </div>

        <div v-else>
          <div class="d-flex justify-space-between align-center mb-5">
            <h3 class="wsDARKER">
              {{ selectedAction.name }}
            </h3>
            <v-btn icon :color="wsACCENT">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>

          <template v-for="(field,index) in selectedAction.fields">
            <ws-text-field
                :key="`field${index}`"
                v-if="field.type === 'string' || field.type === 'number'"
                :label="field.label"
                :placeholder="field.placeholder"
                :number="field.type === 'number'"
                class="mb-5"
            />
          </template>
        </div>


      </v-sheet>
    </div>

  </dash-page-new>
</template>

<style scoped>

</style>

<script>
import automationChip from "@modules/automations/components/UI/automationChip.vue";
import automationSelectChip from "@modules/automations/components/UI/automationSelectChip.vue";
export default {
  name: "DashAutomations",
  components : {
    automationChip,
    automationSelectChip
  },
  props : {
    uuid : {
      type : String
    }
  },
  data() {
    return {
      actions : [],
      selectedChip : 'trigger',
      search : '',
      selectedAction : {},
      triggerTypes : [
        { name : 'New user registration' ,
          value : 'new_user',
          icon : 'mdi-account',
          fields : []
        },
        { name : 'New course order' ,
          value : 'new_user',
          icon : 'mdi-account-school',
          fields : []
        },
      ],
      actionTypes : [
        { name : 'Add new promocode' ,
          description : 'Creates new promocode. Select promocode parameters as its percentage etc',
          value : 'add_promocode',
          icon : 'mdi-sale',
          fields : [
            { type : 'string',
              value : 'name',
              label : 'Name',
              placeholder : 'Promocode name for admin'
            },
            { type : 'number',
              value : 'percent',
              label : 'Percent',
            },
            { type : 'string',
              value : 'course_id',
              label : 'Course Uuid',
              placeholder : 'Enter course uuid'
            },
          ]
        },
        { name : 'Add student to course' ,
          description : 'Creates new promocode. Select promocode parameters as its percentage etc',
          value : 'add_user',
          icon : 'mdi-account-school',
          fields : []
        },
        { name : 'Send Private Telegram Message' ,
          description : 'Creates new promocode. Select promocode parameters as its percentage etc',
          value : 'telegram_message',
          icon : 'mdi-send',
          fields : []
        },
        { name : 'Send Telegram Message to Group' ,
          description : 'Creates new promocode. Select promocode parameters as its percentage etc',
          value : 'telegram_group_message',
          icon : 'mdi-send',
          fields : []
        }
      ],
      entity : {
        name : "Super Automation"
      }
    }
  },
  computed : {
    headers() {
      return [
        { text : 'Name' , value : 'name' }
      ]
    }
  },
  methods : {

    selectAction(action) {
      this.selectedAction = action
      this.selectedChip = action.uuid
    },

    changeActionType(selectedAction ,actionType) {

      const actionIndex = this.actions.findIndex(el=>el.uuid === selectedAction.uuid)
      selectedAction.type = actionType.value
      selectedAction.name = actionType.name
      selectedAction.icon = actionType.icon
      selectedAction.fields = actionType.fields

      if (actionIndex !== -1) {
        this.actions[actionIndex] = this.COPY(selectedAction)
      }

      this.selectedAction = this.COPY(selectedAction)
    },
    addAction() {
      this.actions.push({
        uuid : this.$uuid.v4()
      })
    }
  }
}
</script>

