<template>
  <v-hover #default="{hover}">

    <v-sheet
        :color="hover ? wsLIGHTCARD : null"
        class="wsRoundedLight d-flex align-center pa-2 mb-2 pointer"
        v-ripple
        @click="$emit('select')"
    >
      <v-icon style="background: var(--wsBACKGROUND)" class="mr-3 pa-1 wsRoundedLight" :color="iconColor">{{ item.icon || 'mdi-cog' }}</v-icon>
      <div>
        <h5 class="wsDARKER font-weight-light">{{item.name || 'Choose action'}}</h5>
      </div>

    </v-sheet>

  </v-hover>

</template>

<script>
export default {
  name: "automationChip",
  props : {
    isSelected : {
      type : Boolean,
      default : false
    },
    isTrigger : {
      type : Boolean,
      default : false
    },
    item : {
      type : Object,
      default() {
        return {
          icon : 'mdi-cog'
        }
      }
    }
  },
  computed : {
    iconColor() {
      return this.wsACCENT
    }
  }
}
</script>


<style scoped>

</style>