<template>

  <div>
    <!-- Promo -->
    <page-section class="px-4">
      <v-row class="py-11">
        <v-col cols="12" md="6">
          <div class="d-flex align-center fill-height">
            <div>
              <h5 class="wsACCENT font-weight-regular mt-4">
                {{ $t('homepage.mobile_app.promo.tooltip') }}
              </h5>
              <h1 class="wsDARKER" :style="`font-size: ${SM ? 24 : 42}px`">
                {{ $t('homepage.mobile_app.promo.title') }}
              </h1>

              <div class="mt-10">

                <ws-button
                    @click="openDialog"
                    label="OrderConsultation"
                />
              </div>

            </div>
          </div>

        </v-col>
        <v-col cols="12" md="6">
          <div class="fill-height d-flex align-center">
            <img
                src="@/assets/img/mobile/mobile_app_promo.png"
                width="100%"
                class="wsRoundedHalf"
            />
          </div>
        </v-col>
      </v-row>
    </page-section>

    <!-- Features and Why You Need this -->
    <page-section
        class="pt-16 px-4"
        :title="$t('homepage.mobile_app.features.title')"
    >

      <!-- Features Items -->
      <v-row class="mt-16">
        <v-col v-for="(item,i) in casesSelect" :key="i"
               cols="12" md="6" class="pb-6"
        >
          <v-sheet class="fill-height pa-6  wsRoundedHalf" :color="wsLIGHTCARD">
            <v-icon size="40" :color="wsATTENTION">
              {{ item.icon }}
            </v-icon>
            <h3 class="wsDARKER mt-6">
              {{ $t(item.title) }}
            </h3>
            <h4 class="wsDARKER font-weight-regular mt-4" style="font-size: 16px">
              {{ $t(item.subtitle) }}
            </h4>
          </v-sheet>
        </v-col>
      </v-row>

      <!-- Why You Need this -->
      <v-sheet :color="wsBACKGROUND" class="pa-16 mt-4 wsRoundedHalf d-flex flex-column align-center">
        <img
            src="@/assets/img/mobile/mobile_img.png"
            width="80%"
            class="wsRoundedHalf"
        />
        <h1 class="wsDARKER text-center" :style="`font-size: ${SM ? 24 : 42}px`" style="max-width: 850px;">
          {{ $t('homepage.mobile_app.why.title') }}
        </h1>
        <h5 class="wsACCENT font-weight-regular mt-4 text-center" style="max-width: 850px;">
          {{ $t('homepage.mobile_app.why.text') }}
        </h5>

        <div class="mt-10 ">
          <ws-button
              @click="openDialog"
              label="OrderConsultation"
              outlined
          />
        </div>



      </v-sheet>

    </page-section>

    <!-- Promo 2 -->
    <page-section class="px-4">
      <v-row class="py-11">

        <v-col cols="12" md="7">
          <div class="fill-height d-flex align-center">
            <img
                src="@/assets/img/mobile/mobile_screens.png"
                width="100%"
                class="wsRoundedHalf"
            />
          </div>
        </v-col>

        <v-col cols="12" md="5">
          <div class="d-flex align-center fill-height">
            <div>
              <h4 class="wsDARKER" >
                {{ $t('homepage.mobile_app.promo2.title') }}
              </h4>
              <h5 class="wsACCENT font-weight-regular mt-4">
                {{ $t('homepage.mobile_app.promo2.text1') }}
              </h5>
              <h5 class="wsACCENT font-weight-regular mt-4">
                {{ $t('homepage.mobile_app.promo2.text2') }}
              </h5>

            </div>
          </div>

        </v-col>

      </v-row>
    </page-section>

    <!-- Ready To Start -->
    <page-section class="px-4 pb-16 ">
      <div class="d-flex flex-column align-center">
        <h1 class="wsDARKER text-center" :style="`font-size: ${SM ? 24 : 42}px`" style="max-width: 850px">
          {{ $t('homepage.mobile_app.start.title') }}
        </h1>
        <h5 class="wsACCENT font-weight-regular text-center mt-4" style="max-width: 850px">
          {{ $t('homepage.mobile_app.start.text') }}
        </h5>
      </div>
    </page-section>

    <v-sheet style="position: relative" >
      <v-sheet style="position: absolute; z-index: 0; top : 0 ; left: 0; right: 0; bottom: 50%" color="transparent" ></v-sheet>
      <v-sheet style="position: absolute; z-index: 0; top : 50% ; left: 0; right: 0; bottom: 0" color="var(--wsACCENT)" ></v-sheet>

      <div class="px-4" style="z-index: 100; position: absolute; left: 0; right: 0">
        <v-sheet :color="wsLIGHTCARD" max-width="672" class="mx-auto pa-8 wsRoundedHalf">
          <div class="d-flex align-center">
            <ws-img
                class="mr-4 mt-3 mb-3"
                width="80" height="80"
                :src="require(`@/assets/illustrations/pricing_3.png`)"
            />
            <div class="mb-4">
              <h3 class="wsDARKER">Elite++ App</h3>
              <h1 class="wsDARKER">$149 {{ $t('perMonth') }} </h1>
              <h5 class="wsDARKER font-weight-regular mt-1" style="max-width: 600px">
                {{ $t('homepage.mobile_app.pricing.tooltip') }}
              </h5>
            </div>
          </div>

          <v-divider style="border-color: var(--wsBACKGROUND)" class="mb-4" />

          <h4 v-for="i in 6" :key="'pricing_feature' + i"
              class="wsDARKER font-weight-regular mt-2" style="max-width: 600px">
            - {{ $t(`homepage.mobile_app.pricing.feature_${i}`) }}
          </h4>

          <v-divider style="border-color: var(--wsBACKGROUND)" class="my-4" />

          <h4 class="wsDARKER font-weight-regular mt-1" style="max-width: 600px">
            {{ $t('homepage.mobile_app.pricing.text') }}
          </h4>

          <ws-button
              class="mt-6"
              @click="openDialog"
              label="OrderConsultation"
              block
          />

        </v-sheet>
      </div>

      <!-- Shadow Content -->
      <div class="px-4" style="z-index: 100; opacity: 0">
        <v-sheet :color="wsLIGHTCARD" max-width="672" class="mx-auto pa-8 wsRoundedHalf">
          <div class="d-flex align-center">
            <ws-img
                class="mr-4 mt-3 mb-3"
                width="80" height="80"
                :src="require(`@/assets/illustrations/pricing_3.png`)"
            />
            <div class="mb-4">
              <h3 class="wsDARKER">Elite++ App</h3>
              <h1 class="wsDARKER">$150 {{ $t('perMonth') }} </h1>
              <h5 class="wsDARKER font-weight-regular mt-1" style="max-width: 600px">
                {{ $t('homepage.mobile_app.pricing.tooltip') }}
              </h5>
            </div>
          </div>

          <v-divider style="border-color: var(--wsBACKGROUND)" class="mb-4" />

          <h4 v-for="i in 6" :key="'pricing_feature' + i"
              class="wsDARKER font-weight-regular mt-2" style="max-width: 600px">
            - {{ $t(`homepage.mobile_app.pricing.feature_${i}`) }}
          </h4>

          <v-divider style="border-color: var(--wsBACKGROUND)" class="my-4" />

          <h4 class="wsDARKER font-weight-regular mt-1" style="max-width: 600px">
            {{ $t('homepage.mobile_app.pricing.text') }}
          </h4>

          <ws-button
              class="mt-6"
              @click="openDialog"
              label="OrderConsultation"
              block
          />

        </v-sheet>
      </div>

    </v-sheet>

    <page-section
        background-color="var(--wsACCENT)"
        class="py-16 px-4"
    >

      <h1 class="wsWHITE text-center mt-16 mb-6 text-center">
        {{ $t('homepage.mobile_app.action.title') }}
      </h1>

      <div class="d-flex justify-center">
        <h4 class="wsWHITE text-center mt-5 mb-6 text-center font-weight-regular" style="max-width: 500px">
          {{ $t('homepage.mobile_app.action.text') }}
        </h4>
      </div>


      <div class="d-flex justify-center">
        <ws-button
            class="mt-6"
            @click="openDialog"
            label="OrderConsultation"
            :color="wsWHITE"
            outlined
        />
      </div>





    </page-section>

    <ws-dialog
        v-if="displayDialog"
        v-model="displayDialog"
        title="Консультація по персональному додатку"
        @save="addPartnerRequest"
        :loading="LOADING"
        :hide-save="!!requestSent"
        cancel-text="Закрити"
    >

      <div v-if="!requestSent">
        <v-row no-gutters>
          <v-col cols="12" md="6" :class="[{'pr-2' : !SM}]">
            <ws-text-field
                v-model="entityData.name"
                label="Імʼя"
                placeholder="Введіть ваше імʼя"
                :error="nameError"
                @input="nameError = false"
            />
          </v-col>
          <v-col cols="12" md="6" :class="[{'pl-2' : !SM},{'pt-5' : SM}]">
            <ws-text-field
                v-model="entityData.lastname"
                label="Прізвище"
                placeholder="Введіть ваше прізвище"
                :error="lastnameError"
                @input="lastnameError = false"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6" :class="[{'pr-2' : !SM}]"
          >
            <ws-phone-input
                v-model="entityData.phone"
                label="Телефон"
                placeholder="Введіть актуальний номер"
                hide-details
                :error="phoneError"
                @input="phoneError = false"
            />
          </v-col>
          <v-col cols="12" md="6" :class="[{'pl-2' : !SM},{'pt-5' : SM}]">
            <ws-text-field
                v-model="entityData.email"
                label="Пошта"
                placeholder="Введіть електронну адресу"
                :error="emailError"
                @input="emailError = false"
            />
          </v-col>
        </v-row>

        <ws-text-field
            class="mt-5"
            v-model="entityData.comment"
            label="Коментар"
            placeholder="Додатковий коментар"
            area
        />
      </div>

      <div v-else>
        <h3 class="wsDARKER  font-weight-regular mt-5">Вашу заявку на консультацію надіслано</h3>
        <h4 class="wsACCENT mt-5 font-weight-regular ">Найближчим часом з вами звʼяжеться наш менеджер за вказаними Вами контактними даними</h4>
      </div>


    </ws-dialog>

  </div>

</template>
<script>

import {mapActions} from "vuex";
export default {
  name: "HomeMainPartnerProgram",
  components : {
  },
  data() {
    return {
      entityData : {},

      displayDialog : false,
      phoneError : false,
      emailError : false,
      nameError : false,
      lastnameError : false,
      typeError : false,

      requestSent : false,

      // Selectors
      partnerTypesSelect : [
        { text : 'CRM,LMS експерт' , value : 'CRM,LMS експерт' },
        { text : 'Маю власну онлайн школу' , value : 'Маю власну онлайн школу' },
        { text : 'Інфлуенсер' , value : 'Інфлуенсер' },
        { text : 'Творець контенту' , value : 'Творець контенту' },
        { text : 'Дизайнер сайтів' , value : 'Дизайнер сайтів' },
        { text : 'Професійний контент менеджер' , value : 'Професійний контент менеджер' },
        { text : 'Інше' , value : 'Інше' }
      ],
      casesSelect : [
        { title : 'homepage.mobile_app.features.access.title',
          subtitle : 'homepage.mobile_app.features.access.text',
          icon : 'mdi-clock-outline'
        },
        { title : 'homepage.mobile_app.features.easy_communication.title',
          subtitle : 'homepage.mobile_app.features.easy_communication.text',
          icon : 'mdi-bell-ring'
        },
        { title : 'homepage.mobile_app.features.customization.title',
          subtitle : 'homepage.mobile_app.features.customization.text',
          icon : 'mdi-puzzle'
        },
        { title : 'homepage.mobile_app.features.offline.title',
          subtitle : 'homepage.mobile_app.features.offline.text',
          icon : 'mdi-cloud-download'
        },
        { title : 'homepage.mobile_app.features.comfort.title',
          subtitle : 'homepage.mobile_app.features.comfort.text',
          icon : 'mdi-sync'
        },
        { title : 'homepage.mobile_app.features.leadership.title',
          subtitle : 'homepage.mobile_app.features.leadership.text',
          icon : 'mdi-star-circle'
        },
        { title : 'homepage.mobile_app.features.loyalty.title',
          subtitle : 'homepage.mobile_app.features.loyalty.text',
          icon : 'mdi-emoticon-happy-outline'
        },
        { title : 'homepage.mobile_app.features.optimization.title',
          subtitle : 'homepage.mobile_app.features.optimization.text',
          icon : 'mdi-cellphone-link'
        }
      ],
      howItWorksSelect : [
        { title : 'Зареєструйтесь як партнер',
          subtitle : 'Створіть особистий профіль, щоб розпочати роботу.',
          img : 'mdi-school'
        },
        { title : 'Отримайте реферальне посилання',
          subtitle : 'В кабінеті партнера Ви автоматично отримаєте посилання, яким зможете ділитися.',
          img : 'mdi-school'
        },
        { title : 'Отримайте заробіток',
          subtitle : 'Отримуйте кошти залежно від кількості приведених клієнтів та сплачених ними тарифів.',
          img : 'mdi-school'
        }
      ],
      partnerAdvantagesSelect : [
        { plan : "Базовий тариф",
          advantage : "+10Гб"
        },
        { plan : "Елітний тариф",
          advantage : "+25Гб"
        },
        { plan : "Елітний+ тариф",
          advantage : "+50Гб"
        }
      ],
      collapseTrigger : 0,
      faqSelect : [
        { title : "Яким чином фіксується партнерська комісія?",
          content : "Комісія фіксується після оплати залученим Вами клієнтом. Нарахування відбувається за перші 4 місяці користування клієнтом платформи WeStudy."
        },
        { title : "Коли я отримаю гроші?",
          content : "Перша виплата стане доступною через місяць з моменту першого платежу Вашого клієнта, та за умови залучення двох чи більше клієнтів, що сплатили за користування платформою WeStudy. <br><br>" +
              "\n" +
              "Замовити виплату Ви можете натиснувши на відповідну кнопку в кабінеті партнера \"Отримати виплату\". Після замовлення, ми виплатимо гроші протягом трьох робочих днів в зручний для Вас спосіб. "
        },
        { title : "Які бонуси є для приведених мною клієнтів?",
          content : "Партнерська програма WeStudy передбачає бонусні Гб хмарного сховища для приведених Вами клієнтів. Залежно від обраного тарифу, клієнт отримає наступні бонуси:\n" +
              "<br><br>Базовий тариф: + 10 Гб \n" +
              "<br>Елітний тариф: + 25 Гб\n" +
              "<br>Елітний+ тариф: + 50 Гб"
        },
        { title : "Скільки я можу заробити?",
          content : "Обмежень на кількість приведених клієнтів не існує! Загальний обсяг нарахування коштів складає 200 % від обраного та сплаченого місячного тарифу приведеного Вами Клієнта протягом 4 місяців. Таким чином, за 1 залученого клієнта Ви отримаєте наступну винагороду відповідно до обраного клієнтом тарифу:" +
              "<br>Базовий: 22 дол. США" +
              "<br>Елітний: 74 дол. США" +
              "<br>Елітний+: 158 дол. США" +
              "<br><br>Як приклад: якщо Ви протягом місяця привели 10 клієнтів у кожний з тарифних планів, то Ваша фінальна винагорода складатиме 2540 дол. США",
          expanded : true
        },
      ],

    }
  },
  methods : {
    ...mapActions('adminPartners' , ["ADD_PARTNER_REQUEST"]),

    openDialog() {
      this.phoneError = false
      this.emailError = false
      this.nameError = false
      this.lastnameError = false
      this.typeError = false
      this.requestSent = false

      this.entityData = {}
      this.displayDialog = true
    },
    async addPartnerRequest() {
      if (!this.entityData.phone) {
        this.phoneError = true
        return this.notify('Вкажіть ваш номер телефону' , 'warning')
      }
      if (!this.entityData.email) {
        this.emailError = true
        return this.notify('Вкажіть вашу пошту' , 'warning')
      }

      if (!this.VALIDATE_EMAIL(this.entityData.email)) {
        this.emailError = true
        return this.notify('Не правильний формат пошти' , 'warning')
      }

      if (!this.entityData.name) {
        this.nameError = true
        return this.notify('Вкажіть ваше імєʼя' , 'warning')
      }
      if (!this.entityData.lastname) {
        this.lastnameError = true
        return this.notify('Вкажіть ваше прізвище' , 'warning')
      }
      this.entityData.is_mobile = true

      this.entityData.origin = 'MOBILE_APP'
      let result = await this.ADD_PARTNER_REQUEST(this.entityData)
      if (!result) {
        this.ERROR()
      }
      if (!this.entityData.comment) {
        this.entityData.comment = ''
      }
      this.entityData.comment = 'ЗАМОВЛЕННЯ МОБІЛЬНОГО ДОДАТКУ: \n' + this.entityData.comment

      this.notify('Заявку надіслано' , 'success')

      this.requestSent = true
    }
  }
}
</script>

<style scoped>

</style>