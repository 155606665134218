<template>
  <v-sheet  :color="wsLIGHTCARD" class="wsRoundedHalf pa-8 pb-0">

    <h3 :style="`color : ${wsDARKER}`">{{ $t(title) }}</h3>
    <h4 :style="`color : ${wsDARKER} ; ${textHeight ? `min-height : ${textHeight}` : null}`" style="max-width: 516px" class="font-weight-regular mt-2">{{ $t(text) }}</h4>
    <ws-button v-if="index === 1" :to="localeLink('mobile_app')" class="mt-5" label="Details"></ws-button>
    <v-img class="wsRoundedHalfTop mt-6" :src="require(`@/assets/${image}`)"></v-img>



  </v-sheet>
</template>

<script>
export default {
  name: "possibilityCard",
  props : {
    title : {
      type : String
    },
    text : {
      type : String
    },
    image : {
      type : String
    },
    index : {},
    textHeight : {
      type : String,
      default : null
    },
    imageHeight : {
      type : String,
      default : '550px'
    },

  }
}
</script>

<style scoped>

</style>