<template>
<dash-page-new>
  <h1>Statistics</h1>
</dash-page-new>
</template>


<script>
export default {
  name: "DashMobileAppsStatistics"
}
</script>

<style scoped>

</style>