<template>
<dash-page-new>
  <h1>Description</h1>
</dash-page-new>
</template>


<script>
export default {
  name: "DashMobileAppsDescription"
}
</script>

<style scoped>

</style>