<template>
  <div>

    <v-sheet
        v-for="(question,i) in questions" :key="i"
        style="border: 1px solid var(--wsBACKGROUND)"
        class="mb-5 wsRoundedSemi pa-4"
    >
      <div class="d-flex justify-space-between">
        <h5>{{ $t('Question') }}</h5>

        <ft-select
            @input="handleEvaluation(question, $event)"
            :items="getEvaluationPointsSelect(question.score_points)"
        >
          <ws-button
              :color="!entityData[question.question_id] && entityData[question.question_id] !== 0  ? wsATTENTION : wsACCENT"
              :label-raw="getScoreText(question)"
              icon="mdi-menu-down"
              :click-stop="false"
              text
          />

        </ft-select>

      </div>


      <ws-text-viewer
          :value="question.content"
          style="width: 100%"
      />



      <h5 class="mt-5">{{ $t('Answer') }}</h5>
      <ws-text-viewer
          :value="question.question_reply_json"
          style="width: 100%"
      />
    </v-sheet>
    <div class="d-flex justify-end">
      <ws-button
          @click="finishTest"
          :loading="LOADING"
          label="Next"
          icon="mdi-chevron-right"
          :disabled="disableFinishCondition"
      ></ws-button>
    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "testManualEvaluation",
  props : {
    questions : {
      type : Array,
      default() { return [] }
    },
    studentTestId : {
      type : String
    }
  },
  data() {
    return {
      entityData : {}
    }
  },
  computed : {
    disableFinishCondition() {
      return Object.keys(this.entityData).length !== this.questions.length
    }
  },
  methods : {
    ...mapActions('tests', [
      'FINISH_TEST',
      'FINISH_TEST_ADMIN'
    ]),

    async finishTest() {
      let data = {
        uuid : this.studentTestId,
        results : this.entityData
      }

      await this.FINISH_TEST_ADMIN(data)
      this.$emit('finish-test')

    },
    getScoreText(question) {
      if (!this.entityData[question.question_id] && this.entityData[question.question_id] !== 0 ) {
        return this.$t('Оцінити')
      }

      return `${this.$t('EvaluationPoints')}: ${this.entityData[question.question_id]} / ${question.score_points}`

    },
    handleEvaluation(question , value) {
      this.entityData[question.question_id] = value
      this.entityData = this.COPY(this.entityData)
    },
    getEvaluationPointsSelect(maxPoint) {
      let items = []
      for (let i = 0; i <= maxPoint; i++) {
        items.push({
          text : i , value : i
        })
      }
      return items
    },
  },

}
</script>

<style scoped>

</style>