export default [
    {
        text: 'Mobile',
        value: 'automations',
        icon: 'mdi-cellphone-cog' ,
        module : 'mobileapps',
        permission:'MODULE_MOBILEAPPS',
        id :'mobileapps',
        owner : true,
        children : [
            {
                text: 'mobileapps.descriptions.title',
                value: 'mobileapps/description',
                path: 'mobileapps/description',
                module : 'mobileapps',
            },
            {
                text: 'News',
                value: 'mobileapps/news',
                path: 'mobileapps/news',
                module : 'mobileapps',
            },
            {
                text: 'mobileapps.statistics.title',
                value: 'mobileapps/statistics',
                path: 'mobileapps/statistics',
                module : 'mobileapps',
            }
        ],
    },
]