export default [
    {
        text: 'Automations',
        value: 'automations',
        icon: 'mdi-auto-fix' ,
        path: 'automations',
        module : 'automations',
        permission:'MODULE_AUTOMATIONS',
        id :'automations',

        owner : true
    },
]