<template>
  <v-fade-transition mode="out-in">
    <!-- Loader -->
    <div v-if="loading"
         class="d-flex align-center justify-center"
         :style="mainStyle">

      <div style="width: 150px">
        <v-progress-linear rounded :color="wsACCENT" indeterminate width="200px" />
      </div>

    </div>
    <!-- Content -->
    <div v-else-if="!loading && !error && orderComplete"
         :style="mainStyle"
         class="fill-height d-flex flex-column" >

      <!-- Header-->
      <slot name="header.title" :close="close">
        <div class="d-flex justify-space-between pa-4">
          <h2>{{ $t(title) }}</h2>
          <v-btn @click="close" icon :color="wsACCENT">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </slot>

      <div class="flex-grow-1 overflow-y-auto">
        <slot name="order.complete">

        </slot>
      </div>

    </div>
    <div v-else-if="!loading && !error && !orderComplete"
         :style="mainStyle"
         class="d-flex flex-column desktop-style" >

      <!-- Header-->
      <slot name="header.title" :close="close">
      <div class="d-flex justify-space-between pa-4">
          <h2>{{ $t(title) }}</h2>
          <v-btn @click="close" icon :color="wsACCENT">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </div>
      </slot>

      <!-- Content -->
      <div class="overflow-y-auto px-4">
        <slot>
          <!-- Main Content  -->
        </slot>
      </div>


      <div class="flex-grow-1"></div>

      <!-- Footer : Purchase Buttons  -->
      <v-divider style="border-color : var(--wsBACKGROUND)"  />

      <div class="pa-4">
        <slot name="footer">
          <!-- Footer Content  -->
        </slot>
      </div>


    </div>

    <div v-else-if="error"
         :style="mainStyle"
         class="d-flex align-center justify-center" >

      <div>
        <h4 style="color : var(--wsWARNING)" class="text-center pt-16">Error: {{ errorText }}</h4>
        <div class="d-flex justify-center mt-5">
          <ws-button @click="close" label="Close"></ws-button>
        </div>
      </div>

    </div>

  </v-fade-transition>
</template>


<script>
import {mapActions} from "vuex";

export default {
  name: "embedCourseEnrollButton",
  props : {
    title : {
      type : String
    },
    embed : {
      type : Boolean,
      default : false
    },
    initFunction : {
      type : Function,
    },
    orderComplete : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      styles : {},
      error : false,
      loading : true,
      embedData : {},
      businessAlias : null,
      errorText : null
    }
  },
  computed : {
    mainStyle() {

      let style = `background: ${this.wsLIGHTCARD}; word-wrap: break-word; width: 100%; height: 100%;`

      return style
    },
    utmData () {
      let data = {}
      if (this.$store.state.QUERY) {
        Object.keys(this.$store.state.QUERY).forEach(key => {
          if (key.includes('utm_')) {
            data[key] = this.$store.state.QUERY[key]
          }
        })
      }
      return data
    }
  },
  methods : {
    ...mapActions('business', [
        'GET_BUSINESS_PUBLIC'
    ]),

    close() {
      if (this.embed) {
        window.parent.postMessage({action: 'ws_close_cart'}, '*');
      } else {
        this.$emit('close')
      }
    },

    async initBusiness() {
      let businessResult = await this.GET_BUSINESS_PUBLIC(this.businessAlias)
      if (!businessResult) {
        this.error = true
        this.errorText = 'Business not found'
        return
      }
    },

    async handleEmbed() {

      if (!this.embed) {
        return
      }

      if (!this.$route.query.data) {
        this.errorText = 'Data is wrong or missing: 01'
        this.error = true
        return
      }
      let decoded = null
      try {
        decoded = JSON.parse(atob(this.$route.query.data))
      } catch (e) {
        this.errorText = 'Data is wrong or missing: 02'
        this.error = true
        return
      }

      this.embedData = decoded

      this.$emit('embed-data' , this.embedData)

      if (this.embedData.styles && Object.keys(this.embedData.styles) && Object.keys(this.embedData.styles).length) {
        this.styles = this.embedData.styles
      }

      if (!this.embedData.business) {
        this.errorText = 'Data is wrong or missing: 03'
        this.error = true
        return
      }

      this.businessAlias = this.embedData.business

      await this.initBusiness()

    },

    async handleInitFunction() {
      if (!this.initFunction || this.error) {
        return
      }

      let initResult = await this.initFunction()

      console.log('initResult',initResult)

      if (!initResult.result) {
        this.error = true
        this.errorText = initResult.error
      }

    }

  },
  async mounted() {

    await this.handleEmbed()
    await this.handleInitFunction()
    this.$emit('utm-data' , this.utmData)

    this.loading = false

  }
}
</script>


<style scoped>

</style>