<template>
  <sub-page
      :title="$t('settings.api.title')"
      icon="mdi-api"
      v-model="$store.state.settings.pageData"
      fill-height
  >

    <template #default>

      <!-- Header Actions-->
      <portal to="dash-page-header-action">
        <ws-button
            v-if="navigation === 'tokens'"
            @click="openAddToken"
            label="settings.api.tokens.add" class="mr-2"
        />
        <ws-button
            v-if="navigation === 'webhooks'"
            @click="openAddWebhook"
            label="settings.api.webhooks.add" class="mr-2"
        />
      </portal>


      <!-- Main Content -->
      <ws-navigation
          :items="navigationSelect"
          v-model="navigation"
          no-number
      >

        <template #item.tokens>

          <ws-data-table
              v-if="tokens.length > 0"
              :items="tokens"
              :headers="tokensHeaders"
              disable-pagination
              no-footer
          >

            <template #item.created="{item}">
              <h5>{{ $time(item.created).regularTime() }}</h5>
            </template>

            <template #item.expire="{item}">
              <h5>{{ $time(item.expire).regularTime() }}</h5>
            </template>


          </ws-data-table>
          <div v-else class="py-16 d-flex flex-column align-center">
            <v-icon class="mx-auto" :color="wsBACKGROUND" size="80" >mdi-database-search-outline</v-icon>
            <h3  class="text-center mt-3 wsACCENT">{{  $t('settings.api.tokens.no_content')  }}</h3>
            <h4  class="text-center mt-3 wsACCENT font-weight-regular">{{  $t('settings.api.tokens.no_content_description')  }}</h4>

            <ws-button
                @click="openAddToken"
                label="settings.api.tokens.add" class="mr-2 mt-5"
            />
          </div>

        </template>

        <template #item.webhooks>

          <ws-data-table
              v-if="webhooks.length > 0"
              :items="webhooks"
              :headers="webhooksHeaders"
              disable-pagination
              no-footer
          >

            <template #item.event="{item}">
              <h5 class="wsACCENT">{{ getWebhookEventName(item.event) }}</h5>
            </template>

            <template #item.created="{item}">
              <h5>{{ $time(item.created).regularTime() }}</h5>
            </template>

            <template #item.action="{hover,item}">
              <div style="width: 25px">
                <v-btn v-if="hover" @click="openDeleteWebhook(item)" icon :color="wsACCENT">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </template>



          </ws-data-table>

          <div v-else class="py-16 d-flex flex-column align-center">
            <v-icon class="mx-auto" :color="wsBACKGROUND" size="80" >mdi-database-search-outline</v-icon>
            <h3  class="text-center mt-3 wsACCENT">{{  $t('settings.api.webhooks.no_content')  }}</h3>
            <h4  class="text-center mt-3 wsACCENT font-weight-regular">{{  $t('settings.api.webhooks.no_content_description')  }}</h4>

            <ws-button
                @click="openAddWebhook"
                label="settings.api.webhooks.add" class="mr-2 mt-5"
            />
          </div>



        </template>


      </ws-navigation>


    </template>

    <template #dialog>
      <!-- Token Dialog-->
      <ws-dialog
          v-model="displayTokenDialog"
          :title="$t(!displayNewToken ? 'settings.api.tokens.add' : 'settings.api.tokens.created')"
          @save="addToken"
          :loading="LOADING"
          :hide-save="displayNewToken"
          :cancel-text="displayNewToken ? $t('Close') : $t('Cancel')"
      >
        <div v-if="!displayNewToken">
          <ws-text-field
              v-model="entityData.label"
              :label="$t('Name')"
              :placeholder="$t('EnterName')"
              :error="nameError"
              @input="nameError = false"
          />

          <ws-select
              v-model="entityData.expire_period"
              :items="expirePeriodsSelect"
              :label="$t('settings.api.tokens.expire_label')"
              class="mt-5"
          />
        </div>

        <div v-else>

          <h4 class="wsACCENT text-center font-weight-regular my-10">
            {{  $t('settings.api.tokens.created_description')  }}
          </h4>


          <v-hover #default="{hover}">
            <v-sheet
                @click="CLIPBOARD(newToken.token)"
                :color="hover ? wsLIGHTCARD : 'transparent'"
                class="pa-3 wsRoundedSemi d-flex justify-space-between align-center pointer"
                style="border: 1px solid  var(--wsBACKGROUND) !important"
            >
              <div />
              <h4>{{ $t(newToken.token) }}</h4>
              <v-btn icon :color="wsACCENT">
                <v-icon @click="CLIPBOARD(newToken.token)">mdi-content-copy</v-icon>
              </v-btn>
            </v-sheet>
          </v-hover>


        </div>




      </ws-dialog>

      <!-- WebHook Dialog-->
      <ws-dialog
          v-model="displayWebhookDialog"
          :title="$t('settings.api.webhooks.add')"
          @save="addWebhook"
          :loading="LOADING"
      >

        <ws-select
            v-model="entityData.event"
            :items="webhookEventsSelect"
            :label="$t('Event')"
            :placeholder="$t('ChooseEvent')"
            :error="eventError"
            @input="eventError = false"
        />

        <v-expand-transition>
          <div v-if="entityData.event && entityData.event.includes('course')">
            <ws-select

                v-model="entityData.course_id"
                :items="coursesSelect"
                :label="$t('Course_')"
                :placeholder="$t('ChooseCourse')"
                :autocomplete="coursesSelect.length > 7"

            />
            <h5 class="wsDARKLIGHT font-weight-regular mt-2 pb-5">{{ $t('settings.api.webhooks.course_description') }}</h5>
          </div>

        </v-expand-transition>


        <ws-text-field
            v-model="entityData.url"
            :label="$t('Url')"
            placeholder="https://yourwebhookurl.com"
            :error="urlError"
            @input="urlError = false"
        />

        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
            :error="nameError"
            @input="nameError = false"
            class="mt-5"
        />


      </ws-dialog>
      <!-- Delete WebHook Dialog-->
      <ws-dialog
          v-model="displayDeleteWebhookDialog"
          :title="$t('settings.api.webhooks.delete')"
          @delete="deleteWebhook"
          :loading="LOADING"
          display-confirm-delete
          close-delete
      />

    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "settingsApi",
  components : {

  },
  data() {
    return {
      tokens : [],
      webhooks : [],
      entityData : {},
      nameError : false,
      urlError : false,
      eventError : false,
      navigation : 'tokens',
      displayTokenDialog : false,
      displayWebhookDialog : false,
      displayDeleteWebhookDialog : false,
      loading : true,
      displayNewToken : false,
      newToken : {},
      selectedWebhook : {},
      coursesSelect : []
    }
  },
  computed : {
    webhookEventsSelect() {
      return [
        { text : this.$t('settings.api.webhooks.events.business_lead')                , value : 'business_lead'},
        { text : this.$t('settings.api.webhooks.events.order_course_payment')         , value : 'order_course_payment'},
        { text : this.$t('settings.api.webhooks.events.order_course')                 , value : 'order_course'},
        { text : this.$t('settings.api.webhooks.events.student_course_completion')    , value : 'student_course_completion'},
        { text : this.$t('settings.api.webhooks.events.free_course_enrollment')       , value : 'free_course_enrollment'},

      ]
    },

    expirePeriodsSelect() {
      return [
        { text : '1 місяць' , value : 1 },
        { text : '3 місяць' , value : 3 },
        { text : '6 місяць' , value : 6 },
        { text : '12 місяць' , value : 12 },
        { text : '24 місяць' , value : 24 }
      ]
    },
    tokensHeaders() {
      return [
        { text : this.$t('Name') , value : 'label' },
        { text : this.$t('DateCreated') , value : 'created' },
        { text : this.$t('ActiveTill') , value : 'expire' },
        { text : this.$t('Owner') , value : 'owner' },
        // { text : this.$t('Scope') , value : 'scope' }
      ]
    },
    webhooksHeaders() {
      return [
        { text : this.$t('Event') , value : 'event' },
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Url') , value : 'url' },
        { text : this.$t('DateCreated') , value : 'created' },
        { value : 'action' , sortable : false}
      ]
    },
    navigationSelect() {
      return [
        { text : this.$t('settings.api.tokens.title') , value : 'tokens'},
        { text : this.$t('settings.api.webhooks.title') , value : 'webhooks'}
      ]
    },
    business() {
      return this.$store.state.business.selectedBusiness
    }
  },
  watch : {
    navigation(value) {
      if (value === 'tokens' && !this.tokens.length) {
        this.getTokens()
      }
      if (value === 'webhooks' && !this.webhooks.length) {
        this.getWebhooks()
      }
    }
  },
  methods : {
    ...mapActions('integrations' , [
        'ADD_BUSINESS_INTEGRATION_TOKEN',
        'GET_BUSINESS_INTEGRATION_TOKENS',
        'DELETE_BUSINESS_INTEGRATION_TOKEN',
        'ADD_BUSINESS_INTEGRATION_WEBHOOK',
        'GET_BUSINESS_INTEGRATION_WEBHOOKS',
        'DELETE_BUSINESS_INTEGRATION_WEBHOOK',

    ]),
    ...mapActions('courses' , ['GET_COURSES_SELECT']),

    // WebHooks

    async getWebhooks() {

      let result = await this.GET_BUSINESS_INTEGRATION_WEBHOOKS()

      if ( !result ) {
        this.ERROR()
      }

      if (result !== true) {
        this.webhooks = result
      }
      this.loading = false

    },
    async addWebhook() {

      if (!this.entityData.event) {
        this.eventError = true
        this.notify(this.$t('ChooseEvent'),'warning')
        return
      }

      if (!this.entityData.url) {
        this.urlError = true
        this.notify(this.$t('EnterUrl'),'warning')
        return
      }

      if (!this.VALIDATE_URL(this.entityData.url)) {
        this.urlError = true
        this.notify(this.$t('EnterValidLink'),'warning')
        return
      }

      if (!this.entityData.name) {
        this.nameError = true
        this.notify(this.$t('EnterName'),'warning')
        return
      }


      if (this.entityData.event && !this.entityData.event.includes('course') ) {
        delete this.entityData.course_id
      }

      let result = await this.ADD_BUSINESS_INTEGRATION_WEBHOOK(this.entityData)
      if (!result) {
        return this.ERROR()
      }

      this.webhooks.push(result)
      this.displayWebhookDialog = false
    },
    async deleteWebhook() {
      let result = await this.DELETE_BUSINESS_INTEGRATION_WEBHOOK(this.selectedWebhook.uuid)
      if (!result) {
        return this.ERROR()
      }
      let index = this.webhooks.findIndex( el=> el.uuid === this.selectedWebhook.uuid)
      if ( index === -1 )  {
        return
      }
      this.webhooks.splice(index, 1)
      this.displayDeleteWebhookDialog = false
    },
    openAddWebhook() {
      this.entityData = {}
      this.displayWebhookDialog = true
    },
    openDeleteWebhook(item) {
      this.selectedWebhook = this.COPY(item)
      this.displayDeleteWebhookDialog = true
    },

    // Tokens
    async getTokens() {

      let result = await this.GET_BUSINESS_INTEGRATION_TOKENS()

      if ( !result ) {
        this.ERROR()
      }

      if (result !== true) {
        this.tokens = result
      }

      this.loading = false


    },
    async addToken() {

      if (!this.entityData.label) {
        this.nameError = true
        return this.notify(this.$t('EnterName') , 'warning')
      }
      let result = await this.ADD_BUSINESS_INTEGRATION_TOKEN(this.entityData)
      if (!result) {
        return this.ERROR()
      }

      this.tokens.push(result)

      this.displayNewToken = true
      this.newToken = result

    },
    openAddToken() {
      this.displayNewToken = false
      this.newToken = {}
      this.entityData = {
        expire_period : 12
      }
      this.displayTokenDialog = true
    },

    // Technical

    getWebhookEventName(type) {
      let event = this.webhookEventsSelect.find(el => el.value === type)
      return event ? event.text : type
    }


  },
  async mounted() {
    this.getTokens()
    this.coursesSelect = await this.GET_COURSES_SELECT()
  }
}
</script>

<style scoped>

</style>