<template>
<div :class="fullScreen ? 'fill-height' : ''">
  <span v-if="displayUploaded">

     <wsVideo
         v-if="isComponent"
         :file="src_file"
         :course-id="component.course_id"
         :full-screen="fullScreen"
         :is-collapsed="isCollapsed"
         student
         @toggleCompact="$emit('toggleCompact')"
         @controlsDisplay="$emit('controlsDisplay' , $event)"
         :externalHover="externalHover"
     />

    <wsVideo
        v-else-if="isFile"
        :file="src_file"
        :full-screen="fullScreen"
        :element-id="elementId"
        @toggleCompact="$emit('toggleCompact')"
        @controlsDisplay="$emit('controlsDisplay' , $event)"
        :externalHover="externalHover"

    />
  </span>

  <template>
    <v-hover #default="{hover}">
      <div class="fill-height" style="position: relative">

        <v-sheet v-if="hover && fullScreen"
                 style="position: absolute; z-index: 999; top: 65px; left: 14px"
                 class="pa-1 wsRounded"
                 color="grey darken-3"
                 dark
        >
          <v-btn
              @click="$emit('toggleCompact')"
              icon
          >
            <v-icon >
              {{ isCollapsed ? 'mdi-open-in-new' : 'mdi-picture-in-picture-bottom-right' }}
            </v-icon>
          </v-btn>
        </v-sheet>

         <wsEmbed
             v-if="displayEmbed"
             @mouseover="displayCollapseButton = true"
             @mouseleave="displayCollapseButton = false"
             :src="src_url"
             :height="fullScreen ? '100%' : (SM ? '200' : '400')"
         />

      </div>

    </v-hover>




  </template>


</div>
</template>

<script>
export default {
  name: "wsVideoViewer",
  props : {
    component : {
      type : Object,
    },
    file : {
      type : Object,
    },
    url : {
      type : String
    },
    fullScreen : {
      type : Boolean,
      default : false
    },
    isCollapsed : {
      type : Boolean,
      default : false
    },
    externalHover : {
      type : Boolean,
      default : false
    },
    elementId : {
      type : Number,
      default : null
    }

  },
  data() {
    return {
      displayCollapseButton : false,
      src_file : {},
      src_url : null,
      displayUploaded : false,
      displayEmbed : false,
      isComponent : false,
      isFile : false,
    }
  },
  watch : {
    component : {
      handler() {
        this.initData()
      },
      deep : true
    },
    displayEmbed(value) {
      this.$emit('embed' , value)
    }
  },
  methods : {

    parseComponent() {

      if ( !this.component ) {
        return this
      }

      if ( this.component.file ) {
        this.flushData()
        this.src_file = this.component.file
        this.isComponent = true
        this.displayUploaded = true
      }

      if ( !this.component.video && this.component.video_translated ) {
        this.component.video = this.component.video_translated
      }

      if ( !this.component.video ) {
        return this
      }

      if ( this.component.video.is_uploaded ) {
        this.flushData()
        this.src_file = this.component.video.file
        this.isComponent = true
        this.displayUploaded = true
      }



      if ( !this.component.video.is_embed ) {
        return this
      }

      if ( this.component.video.url ) {
        this.flushData()
        this.src_url = this.component.video.url
        this.displayEmbed = true
      }

      return this

    },
    parseUrl() {

      if ( this.url ) {
        this.flushData()
        this.src_url = this.url
        this.displayEmbed = true
      }

      return this
    },
    parseFile() {

      if ( !this.file ) {
        return this
      }
      if ( !this.file.uuid ) {
        return this
      }
      this.flushData()

      this.src_file = this.file
      this.isFile = true
      this.displayUploaded = true

      return this
    },
    flushData() {

      this.src_file = null
      this.src_url = null
      this.displayEmbed = false
      this.displayUploaded = false
      this.isComponent = false
      this.isFile = false

      return this

    },

    initData() {

      this
          .parseComponent()
          .parseFile()
          .parseUrl()

    }

  },
  mounted() {
    this.initData()
  }
}
</script>

<style scoped>

</style>