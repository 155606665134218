<template>
<div style="border : 1px solid var(--wsBACKGROUND)"
     :style="`outline : ${isSelected ? 3 : 1}px solid var(--wsBACKGROUND)`"
     class="wsRounded d-flex pa-4 mb-5 pointer"
     v-ripple
     @click="$emit('select')"
>
  <v-icon class="mr-3" large :color="iconColor">{{ item.icon || 'mdi-cog' }}</v-icon>
  <div>
    <h5 class="wsDARKER">{{item.name || 'Choose action'}}</h5>
    <h5 class="wsACCENT font-weight-regular">Description will be here</h5>
  </div>

</div>
</template>

<script>
export default {
  name: "automationChip",
  props : {
    isSelected : {
      type : Boolean,
      default : false
    },
    isTrigger : {
      type : Boolean,
      default : false
    },
    item : {
      type : Object,
      default() {
        return {
          icon : 'mdi-cog'
        }
      }
    }
  },
  computed : {
    iconColor() {
      if (!this.item.type) {
        return this.wsBACKGROUND
      }
      return this.isTrigger ? this.wsACCENT : this.wsATTENTION
    }
  }
}
</script>


<style scoped>

</style>