export default {
    computed : {
        CURRENCY_SELECT() {
            return [
                { text : this.$t('UAH') , value : "UAH" },
                { text : this.$t('USD') , value : "USD" },
                { text : this.$t('EUR') , value : "EUR" },
                { text : this.$t('PLN') , value : "PLN" }
            ]
        },
        BUSINESS_CURRENCY() {
            return this.$store.state.business.selectedBusiness.currency || "UAH"
        }
    },
    methods : {
        LIQPAY_checkout(liqData) {

            var f = document.createElement("form");
            f.setAttribute('method',"POST");
            f.setAttribute('action',liqData.action);
            f.setAttribute('accept-charset',liqData.acceptCharset);

            var i = document.createElement("input"); //data, string
            i.setAttribute('type',"hidden");
            i.setAttribute('name',"data");
            i.setAttribute('value',liqData.data);

            var s = document.createElement("input"); //signature, strung
            s.setAttribute('type',"hidden");
            s.setAttribute('name',"signature");
            s.setAttribute('value',liqData.signature);

            f.appendChild(i);
            f.appendChild(s);

            document.getElementById('virtual_hidden').appendChild(f);
            f.submit();
            document.getElementById('virtual_hidden').removeChild(f)
        },
        WAYFORPAY_INVOICE(wayData) {

            let keys = [
                "merchantAccount",
                "merchantDomainName",
                "merchantSignature",
                "serviceUrl",
                "returnUrl",
                "orderReference",
                "orderDate",
                "amount",
                "currency",
                "productName",
                "productPrice",
                "productCount",
                "clientAccountId",
            ]
            var f = document.createElement("form");
            f.setAttribute('method',"POST");
            f.setAttribute('action','https://secure.wayforpay.com/pay');
            f.setAttribute('accept-charset','utf-8');

            function addInput(key,value) {
                let input = document.createElement("input");
                input.setAttribute('type',"hidden");
                input.setAttribute('name',key);
                input.setAttribute('value',value);
                f.appendChild(input);
            }

            Object.keys(wayData).forEach(key => {
                if ( !keys.includes(key) ) {
                    return
                }
                if ( !Array.isArray(wayData[key]) ) {
                    addInput(key, wayData[key])
                } else {
                    wayData[key].forEach(value => {
                        addInput(`${key}[]`, value)
                    })
                }
            })

            document.getElementById('virtual_hidden').appendChild(f);
            f.submit();
            document.getElementById('virtual_hidden').removeChild(f)
        },
    }
}