import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const projects = [
    'university',
    'westudy',
    'fine'
]

// technical variables
var homeDomain = true;
var businessDashboardRoutes = []
var businessPublicRoutes = []
var marketRoutes = []
var marketOn = process.env.VUE_APP_MARKET_ON === 'true'

let homePublicRoutes = []
let mode = process.env.VUE_APP_PROJECT
if (!projects.includes(mode) ) {
    mode = 'westudy'
}
switch (mode) {
    case 'university' : mode = 'wsu'; break;
}

// technical functions
function checkMainDomain(store) {
    let host = window.location.host;
    homeDomain = [store.state.hostname,store.state.second_hostname].includes(host) ||
        ['www.' + store.state.hostname,'www.' + store.state.second_hostname].includes(host)

    if ( window.location.host.includes('192.168.1.231') ) {
        homeDomain = true
    }

}
function dashboard( root = null ) {
    return 'dashboard' + (homeDomain ? `/:alias${root ? '' : '/'}` : '') ;
}
function business( root = false ) {
    return (homeDomain ? `:alias${root ? '' : '/'}` : '') ;
}
function generateRoutes(store) {

    let routes = businessRoutes()

    routes = homeDomain ? homePageRoutes().concat(routes) : routes
    routes.push( { path: "*", component: () => import("@/components/pages/homepage/Page404")  } , )

    let localeRoutes = [
        { path: '/newsletter_link' , props : true, component : () => import("@/components/pages/newsletterLinkParses"), },
        { path: '/embed/course', props : {embed : true},  component : () => import("@/components/pages/technical/cart/course/CourseCart.vue"), },
        { path: '/:lang([A-z]{2})?',
            component: () => import("@/localeRouterView"),
            props : true,
            children : routes
        },

    ]
    let router = new Router({
        mode: process.env.NODE_ENV === 'production' ? 'history' : 'history',
        routes: localeRoutes,
        linkActiveClass: null,
    })
    router.beforeEach((to,from,next) => {
        if ( store.state.routeChangeCount < 2 ) {
            store.state.routeChangeCount++
        }
        if ( store.state.routeChangeCount === 2 ) {
            store.state.isRouteChanged = true
        }

        next()
    })
    return router

}
function loadModulesRoutes() {
        const context = require.context('@modules', true, /routes\.js$/);


        context.keys().forEach(key => {

            let moduleProjectName = null;
            let isProjectModule = key.includes('_');
            if (isProjectModule) {
                moduleProjectName = key.split('_')[0];
                moduleProjectName = moduleProjectName.slice(2)
            }

            console.log('Router Module' , key , 'first condition' , key.includes('_homepage') && !homePublicRoutes.length ,'mode' , mode , 'moduleProjectName' , moduleProjectName)

            const moduleRoutes = context(key).default;
            if ( key.includes('_homepage') && !homePublicRoutes.length) {
                if ( mode === moduleProjectName ) {
                    homePublicRoutes = moduleRoutes
                }
            } else if (!isProjectModule || (isProjectModule && moduleProjectName === mode )) {
                if ( moduleRoutes.businessDashboard ) {
                    businessDashboardRoutes = [...businessDashboardRoutes , ...moduleRoutes.businessDashboard]
                }

                if ( moduleRoutes.courseMarketMain) {
                    marketRoutes = [...moduleRoutes.courseMarketMain]
                }

                if ( moduleRoutes.businessPublic ) {
                    businessPublicRoutes = [...businessPublicRoutes , ...moduleRoutes.businessPublic]
                }
            }


        });
}

// routes
function businessRoutes() {
    return [

        // Technical pages
        { path: "email_unsubscribe"  , component:() => import("@/components/pages/technical/UnsubscribeEmailPage") , props : true },
        { path: 'confirm', component: () => import("@/components/pages/LoadingPage") },

        // :: BUSINESS DASHBOARD ::
        { path: dashboard(true) , component: () => import ("@/components/pages/businessDashboard/PageBusinessDashboard"), props: true, children :
                [
                    // FineTrading
                    { path: ""                           , component: () => import("@/components/pages/businessDashboard/dashBusiness/dashBusinessIndex")           , props : true },
                    { path: "dashboard"                  , component: () => import("@/components/pages/businessDashboard/dashBusiness/dashBusinessAdminWestudy")    , props : true },
                    { path: "start"                      , component: () => import("@/components/pages/businessDashboard/businessGetStarted/BusinessGetStarted")       , props : true },
                    { path: "partners"                   , component: () => import("@/components/pages/businessDashboard/businessPartners/BusinessPartners")       , props : true },

                    { path: "news"                       , component: () => import("@/components/pages/businessDashboard/businessNews/BusinessDashboardNews")       , props : true },
                    { path: "news/:uuid"                 , component: () => import("@/components/pages/businessDashboard/businessNews/businessEditNews")             , props : true },
                    { path: "leads"                      , component: () => import("@/components/pages/businessDashboard/businessLeads/BusinessDashboardLeads")      , props : true },
                    { path: "discount"                , component: () => import("@/components/pages/businessDashboard/discounts/BusinessDiscount")   , props : true , children : [
                            { path: "permanent"       , component: () => import("@/components/pages/businessDashboard/discounts/permanentDsicount")    , props : true },
                            { path: "promo"               , component: () => import("@/components/pages/businessDashboard/discounts/promoCodes")   , props : true },
                            { path: "loyalty"              , component: () => import("@/components/pages/businessDashboard/discounts/loyaltySystem")  , props : true },
                        ]
                    },
                    { path: "referral"                    , component: () => import("@/components/pages/businessDashboard/referral/BusinessReferral") , props : true  },
                    { path: "referral/:code"              , component: () => import("@/components/pages/businessDashboard/referral/viewReferral")    , props : true  },
                    { path: "gamification", component: () => import("@/components/pages/businessDashboard/businessGamification/DashBusinessGamification"), props: true, children: [
                            { path: ""               , component:() => import("@/components/pages/businessDashboard/businessGamification/achievementsStatistics")    , props : true },
                            { path: "settings"                   , component:() => import("@/components/pages/businessDashboard/businessGamification/achievementsSettings")    , props : true },
                        ]
                    },

                    { path: "orders/new"                  , component: () => import("@/components/pages/businessDashboard/businessOrders/CreateOrder") , props : true },

                    { path: "design"                      , component: () => import("@/components/pages/businessDashboard/design/DashBusinessDesign")          , props : true },

                    { path: "settings", component: () => import("@/components/pages/businessDashboard/businessSettings/BusinessSettings"), props: true, children: [
                            { path: "general"               , component:() => import("@/components/pages/businessDashboard/businessSettings/settingsGeneral")    , props : true },
                            { path: "crm"                   , component:() => import("@/components/pages/businessDashboard/businessSettings/settingsCrm")    , props : true },
                            { path: "payment"               , component:() => import("@/components/pages/businessDashboard/businessSettings/settingsPayments"), props : true },
                            { path: "delivery"              , component:() => import("@/components/pages/businessDashboard/businessSettings/BusinessDashboardDeliveries") , props : true },
                            { path: "managers"              , component:() => import("@/components/pages/businessDashboard/businessSettings/businessManagers/BusinessDasboardManagers") , props : true  },
                            { path: "storage"               , component:() => import("@/components/pages/businessDashboard/businessSettings/settingsStorage") , props : true  },
                            { path: "customization"         , component:() => import("@/components/pages/businessDashboard/businessSettings/settingsPersonalization.vue") , props : true  },
                            { path: "api"                   , component:() => import("@/components/pages/businessDashboard/businessSettings/settingsApi.vue") , props : true  },
                            { path: "manager/:uuid"         , component:() => import("@/components/pages/businessDashboard/businessSettings/businessManagers/businessManagerView") , props : true },
                        ]
                    },

                    { path: "modules"               , component:() => import("@/components/pages/businessDashboard/businessSettings/BusinessDashboardModules")     , props : true },
                    { path: "general"               , component:() => import("@/components/pages/businessDashboard/businessSettings/BusinessDashboardGeneral")     , props : true },
                    { path: "domains"               , component:() => import("@/components/pages/businessDashboard/businessSettings/BusinessDashboardDomains")     , props : true },
                    { path: "users"                 , component:() => import("@/components/pages/businessDashboard/businessUsers/BusinessUsers")                   , props : true },
                    { path: "newsletter"            , component:() => import("@/components/pages/businessDashboard/businessNewsletter/BusinessNewsletter")         , props : true , children : [
                            { path: ""              , component: () => import("@/components/pages/businessDashboard/businessNewsletter/newslettersDashboard")      , props : true },
                            { path: "telegram"      , component: () => import("@/components/pages/businessDashboard/businessNewsletter/telegram/telegramlNewslettersTable") , props : true }
                        ]},

                    { path: "newsletter/email"         , component:() => import("@/components/pages/businessDashboard/businessNewsletter/email/EmailNewsletters")             , props : true , children : [
                            { path: ""                 , component:() => import("@/components/pages/businessDashboard/businessNewsletter/email/emailNewslettersTable")              , props : true },
                            { path: "templates"        , component:() => import("@/components/pages/businessDashboard/businessNewsletter/email/emailNewslettersTemplates")          , props : true },
                            { path: "auto"             , component:() => import("@/components/pages/businessDashboard/businessNewsletter/email/emailNewslettersAutomated")           , props : true },
                            { path: "settings"         , component:() => import("@/components/pages/businessDashboard/businessNewsletter/email/emailNewslettersSettings")            , props : true },
                            { path: "view/:uuid"       , component:() => import("@/components/pages/businessDashboard/businessNewsletter/email/viewNewsletter/viewEmailNewsletter"), props : true }
                        ]},

                    // { path: "newsletter/telegram"      , component: telegramlNewslettersTable             , props : true ,
                    // children : [
                    //     { path: ""                 , component: telegramlNewslettersTable              , props : true },
                    //     // { path: "templates"        , component: telegramNewslettersTemplates          , props : true },
                    //     { path: "auto"             , component: telegramNewslettersAutomated          , props : true },
                    //  //   { path: "settings"         , component: newslettersSettings           , props : true },
                    //     { path: "view/:uuid"      , component: viewTelegramNewsletter          , props : true }
                    // ]
                    // },
                    { path: "project-manager"            , component:() => import("@/components/pages/businessDashboard/businessProjectManager/BusinessProjectManager")  , props : true , children : [
                            { path: ""   , component:() => import("@/components/pages/businessDashboard/businessProjectManager/projects/Projects")  , props : true },
                            { path: "meetings"   , component:() => import("@/components/pages/businessDashboard/businessProjectManager/Meetings")  , props : true },
                            { path: "tasks"   , component:() => import("@/components/pages/businessDashboard/businessProjectManager/Tasks")  , props : true },
                    ]},
                    { path: "documentflow"            , component:() => import("@/components/pages/businessDashboard/businessDocumentflow/BusinessDocumentFlow")  , props : true , children : [
                            { path: ""   , component:() => import("@/components/pages/businessDashboard/businessDocumentflow/documentFlowDocuments")  , props : true },
                            { path: "settings"   , component:() => import("@/components/pages/businessDashboard/businessDocumentflow/documentFlowSettings")  , props : true },
                            { path: "signature"   , component:() => import("@/components/pages/businessDashboard/businessDocumentflow/documentFlowSignature")  , props : true },
                        ]},
                    { path: "project-manager/projects/:uuid"            , component:() => import("@/components/pages/businessDashboard/businessProjectManager/projects/ProjectViewer")  , props : true , children : [
                            { path: ""                  , component:() => import("@/components/pages/businessDashboard/businessProjectManager/projects/projectEditor")  , props : true },
                            { path: "dashboard"         , component:() => import("@/components/pages/businessDashboard/businessProjectManager/projects/projectDashboard")  , props : true },
                            { path: "participants"      , component:() => import("@/components/pages/businessDashboard/businessProjectManager/projects/projectParticipants")  , props : true },
                            { path: "meetings"          , component:() => import("@/components/pages/businessDashboard/businessProjectManager/projects/projectMeetings")  , props : true },
                            { path: "files"             , component:() => import("@/components/pages/businessDashboard/businessProjectManager/projects/projectFiles")  , props : true },
                        ]},
                    { path: "notifications"              , component:() => import("@/components/pages/businessDashboard/notificationCenter/DashNotificationCenter")       , props : true },
                    { path: "subscribers"                , component:() => import("@/components/pages/businessDashboard/businessSubscribers/BusinessDasboardSubscribers")  , props : true },
                    { path: "subscribers/:uuid"          , component:() => import("@/components/pages/businessDashboard/businessSubscribers/ClientCard")                   , props : true , children : [
                            { path : "" , component:() => import("@/components/pages/businessDashboard/businessSubscribers/clientTimeline")  , props : true  },
                            { path : "timeline" , component:() => import("@/components/pages/businessDashboard/businessSubscribers/clientTimeline") , props : true  },
                            { path : "profile"  , component:() => import("@/components/pages/businessDashboard/businessSubscribers/clientProfile")  , props : true  },
                            { path : "orders"   , component:() => import("@/components/pages/businessDashboard/businessSubscribers/clientOrders")    , props : true  },
                            { path : "courses"  , component:() => import("@/components/pages/businessDashboard/businessSubscribers/clientCourses")   , props : true  }
                        ]},
                    { path: "warehouse"                  , component:() => import("@/components/pages/businessDashboard/businessWarehouse/BusinessDashboardWarehouse")   , props : true },
                    { path: "chatBots"                   , component:() => import("@/components/pages/businessDashboard/businessChatBots/BusinessDashboardChatBots")     , props : true },
                    { path: "chatBots/constructor"       , component:() => import("@/components/pages/businessDashboard/businessChatBots/ChatBotConstructor")            , props : true },
                    { path: "googleMaps"                 , component:() => import("@/components/pages/businessDashboard/businessGoogleLocations/BusinessDashboardGoogleMaps")  , props : true },
                    { path: "cart"                       , component:() => import("@/modules/products/components/businessUserCart/BusinessUserCart")             , props : true },
                    { path: "statistics"                 , component:() => import("@/components/pages/businessDashboard/businessStatistics/BusinessDashboardStatistics")  , props : true ,  children : [
                            { path: "courses"            , component:() => import("@/components/pages/businessDashboard/businessStatistics/statisticsCourses") , props : true }
                        ]
                    },
                    { path: "profile"                    , component:() => import("@/components/pages/dashboard/profile/DashProfile")                  , props : true },

                    { path: "help"                       , component:() => import("@/components/pages/businessDashboard/businessHelp/BusinessHelp")                , props : true },

                    { path: "plans_payments"             , component:() => import("@/components/pages/businessDashboard/businessPlansAndPayments/PlansAndPayments") , props : true , children : [
                            { path: ""                   , component:() => import("@/components/pages/businessDashboard/businessPlansAndPayments/planAndPaymentsOverview") , props : true },
                            { path: "orders"             , component:() => import("@/components/pages/businessDashboard/businessPlansAndPayments/planAndPaymentsActiveOrders") , props : true },
                            { path: "orders/:orderId"    , component:() => import("@/components/pages/businessDashboard/businessPlansAndPayments/planAndPaymentsOrder") , props : true },
                            { path: "archive"            , component:() => import("@/components/pages/businessDashboard/businessPlansAndPayments/planAndPaymentsArchivedOrders"), props : true },
                    ]},
                    { path: "storage"                   , component:() => import("@/components/pages/businessDashboard/businessStorage/BusinessDashboardStorage")  , props : true },


                    // :: WeStudy ::
                    // Student


                    { path: "student_achievements"       , component:() => import("@/components/pages/westudy/student/StudentAchievements/StudentAchievements")       , props : true },
                    { path: "certificates"               , component:() => import("@/components/pages/westudy/student/StudentCertificates/StudentCertificates")       , props : true },
                    { path: "certificates/view/:uuid"    , component:() => import("@/components/pages/westudy/student/StudentCertificates/viewCertificate")       , props : true },
                    { path: "courses/all"                , component:() => import("@/components/pages/westudy/student/PageCoursesStudentAll")       , props : true },
                    { path: "courses/student"            , component:() => import("@/components/pages/westudy/student/PageCoursesStudent")          , props : true },
                    { path: "courses/:uuid/student"      , component:() => import("@/components/pages/westudy/student/StudentCourse/StudentCourse")               , props : true },
                    { path: "courses/lecture/:uuid"      , component:() => import("@/components/pages/westudy/student/studentLecture")              , props : true },
                    { path: "courses/component/:uuid"    , component:() => import("@/components/pages/westudy/student/studentComponent")            , props : true },
                    { path: "courses/task/:uuid"         , component:() => import("@/components/pages/westudy/student/studentTask")                 , props : true },
                    { path: "courses/test/:uuid"         , component:() => import("@/components/pages/westudy/tests/studentTest")                 , props : true },
                    { path: "courses/test/result/:uuid"  , component:() => import("@/components/pages/westudy/tests/studentTestResult")            , props : true },
                    //Admin
                    { path: "westudy/orders"             , component:() => import("@/components/pages/westudy/admin/DashCourseOrders")            , props : true },
                    { path: "courses"                    , component:() => import("@/components/pages/westudy/admin/PageCourses")                 , props : true },
                    { path: "courses/:uuid/info"         , component:() => import("@/components/pages/westudy/student/studentCourseInfo")         , props : true },
                    { path: "lectures/edit/:uuid"        , component:() => import("@/components/pages/westudy/lecturesEditor")              , props : true },
                    { path: "tasks/edit/:uuid"           , component:() => import("@/components/pages/westudy/tasksEditor")                 , props : true },
                    { path: "tests/edit/:uuid"           , component:() => import("@/components/pages/westudy/testEditor")                   , props : true },
                    { path: "courses/test/preview/:uuid" , component:() => import("@/components/pages/westudy/tests/DashTestPreview")             , props : true },
                    { path: "courses/:uuid"              , component:() => import("@/components/pages/westudy/admin/course/DashCourse")                  , props : true  ,  children :
                            [
                                { path: "editor"                       , component:() => import("@/components/pages/westudy/admin/course/coureseEditor/courseEditor"),                props : true,  },
                                { path: "description"                  , component:() => import("@/components/pages/westudy/admin/course/courseDescription"),           props : true,  },
                                { path: "settings"                     , component:() => import("@/components/pages/westudy/admin/course/courseSettings"),              props : true,  },
                                { path: "registration"                 , component:() => import("@/components/pages/westudy/admin/course/courseRegistration.vue"),      props : true,  },
                                { path: "calendar"                     , component:() => import("@/components/pages/westudy/admin/course/courseSchedule/courseCalendar"),              props : true,  },
                                { path: "offline_schedule"             , component:() => import("@/components/pages/westudy/admin/course/courseSchedule/courseOfflineScheduleEditor"), props : true,  },
                                { path: "payment_plans"                , component:() => import("@/components/pages/westudy/admin/course/coursePaymentPlans"),          props : true,  },
                                { path: "payment_plans/editor"         , component:() => import("@/components/pages/westudy/admin/course/coursePaymentPlansEditor"),    props : true,  },
                                { path: "study_schedule"               , component:() => import("@/components/pages/westudy/admin/course/courseStudyCalendar"),         props : true,  },
                                { path: "study_schedule/:enrollmentId" , component:() => import("@/components/pages/westudy/admin/course/courseSchedule/courseScheduleEditor"),        props : true,  },
                                { path: "students"                     , component:() => import("@/components/pages/westudy/admin/course/courseStudents"),              props : true,  },
                                { path: "certificates"                 , component:() => import("@/components/pages/westudy/admin/course/courseCertificates/courseCertifcates"),           props : true,  },
                                { path: "evaluation/components"              , component:() => import("@/components/pages/westudy/admin/course/evaluation/evaluationComponents"), props : true,  },
                                { path: "evaluation/components/:componentId" , component:() => import("@/components/pages/westudy/admin/course/evaluation/evaluationComponentsStudents") , props : true,  },
                                { path: "evaluation/students"                , component:() => import("@/components/pages/westudy/admin/course/evaluation/evaluationStudents"), props : true,  },
                                { path: "evaluation/students/:studentId"     , component:() => import("@/components/pages/westudy/admin/course/evaluation/evaluationStudentsComponents"), props : true,  },
                                { path: "evaluation/events"                  , component:() => import("@/components/pages/westudy/admin/course/evaluation/evaluationNotifications"), props : true,  },
                                { path: "question_banks"                     , component:() => import("@/components/pages/westudy/admin/course/questionBanks/courseQuestionBanks"),         props : true,  },
                                { path: "question_banks/edit/:bankId"        , component:() => import("@/components/pages/westudy/admin/course/questionBanks/questionBankEditor")      ,    props : true },
                                { path: "comments"                           , component:() => import("@/components/pages/westudy/admin/course/courseComments"),             props : true,  },
                                { path: "comments/:componentId"              , component:() => import("@/components/pages/westudy/admin/course/courseComponentComments"),            props : true,  },

                            ]
                    },

                    ...businessDashboardRoutes,
                ]
        },
        // :: BUSINESS PUBLIC PAGES ::

        { path: business() + "payment/:order"         , component:() => import("@/components/pages/redirectPages/PaymentRedirect")      , props : true },
        { path: business() + "/course/enroll/:uuid/"  , component:() => import("@/components/pages/technical/CourseRegistrationPage") , props : true },
        { path: business() , name : "businessPublic"  , component:() => import( "@/components/AvalonLoader") , props:true , children :
                [
                    ...businessPublicRoutes,
                    { path: ":editor?"                 , component:() => import("@/components/templates/TemplateLoaderPagesSingle")  , props : true },
                    { path: "document/check" , component:() => import("@/components/AvalonEditor/View/checkDocumentPage")  , props : true },
                    { path: "page/:pageAlias/:editor?" , component:() => import("@/components/templates/TemplateLoaderPagesSingle")  , props : true },
                    { path: "page/:course/course/:editor?" , component:() => import("@/components/AvalonEditor/Editor/avalonCoursePage")   , props : true },
                    { path: "blank/homepage/:editor?"  , props : true },
                    { path: "news/:newsAlias/:editor?"  , component:() => import("@/components/templates/TemplateLoaderNewsSingle")   , props : true },
                    { path: "course/:course/:editor?"   , component:() => import("@/components/templates/TemplateLoaderCourseSingle") , props : true },
                    { path: ":notFound(.*)"           , component:() => import("@/components/templates/TemplateLoaderPagesSingle") }
                ],
        },

        { path: business() + "edit"   , component: () => import("@/components/templates/TemplateLoader") , props : true , children :
                [
                    { path: ""        , component: () => import("@/components/templates/TemplateLoaderPagesSingle") , props : true }
                ]
        },

    ]
}
function homePageRoutes() {


    if ( marketOn && mode === 'westudy') {
        homePublicRoutes.unshift(marketRoutes)
    }


    let fineDashboardRoutes  = [
        {
            path: "avalon_editor", component:() => import("@/components/AvalonEditor/ADMIN/AvalonEditorSuperadmin"), props: true ,children:
                [

                    { path: "templates"                        , component:() => import("@/components/AvalonEditor/ADMIN/templates/adminTemplates"), props: true},
                    { path: "templates/:templateId"            , component:() => import("@/components/AvalonEditor/ADMIN/templates/adminTemplateEdit"), props: true  },
                    { path: "templates/:templateId/blocks"     , component:() => import("@/components/AvalonEditor/ADMIN/blocks/adminBlocks"), props: true  },
                    { path: "templates/:templateId/blocks/:id" , component:() => import("@/components/AvalonEditor/ADMIN/blocks/adminBlockEdit"), props: true  },
                    { path: "templates/:templateId/pages"      , component:() => import("@/components/AvalonEditor/ADMIN/pages/adminPages"), props: true  },
                    { path: "templates/:templateId/pages/:id"  , component:() => import("@/components/AvalonEditor/Editor/avalonPageEditor"), props: true  },
                    { path: "pages"      , component:() => import("@/components/AvalonEditor/ADMIN/pages/adminPages"), props: true},
                    { path: "pages/:id"  , component:() => import("@/components/AvalonEditor/Editor/avalonPageEditor") , props: true},
                    { path: "blocks"     , component:() => import("@/components/AvalonEditor/ADMIN/blocks/adminBlocks"), props: true},
                    { path: "blocks/:id" , component:() => import("@/components/AvalonEditor/ADMIN/blocks/adminBlockEdit") , props: true},
                    { path: "headers"    , component:() => import("@/components/AvalonEditor/ADMIN/headers/adminHeaders"), props: true},
                    { path: "pages/:id"  , component:() => import("@/components/AvalonEditor/ADMIN/headers/adminHeaderEdit"), props: true},
                ]
        },
        { path: "dashboard", component: () => import("@/components/pages/dashboard/PageDashboard"), children :
                [
                    { path: ""                     , component: () => import("@/components/pages/dashboard/dash/DashIndex") },
                    // Student
                    { path: "courses"              , component: () => import("@/components/pages/dashboard/student/DashMainCourses") },
                    // SuperAdmin
                    { path: "support"                  , component:() => import("@/components/pages/adminDashboard/support/AdminSupport")                ,  props : true , children : [
                            { path: ""                 , component:() => import("@/components/pages/adminDashboard/support/adminRequestsList") ,  props : true   },
                            { path: "manager"          , component:() => import("@/components/pages/adminDashboard/support/adminRequestsManager") ,  props : true   },
                            { path: "faq"              , component:() => import("@/components/pages/adminDashboard/support/faq/DashAdminFaq")                          },
                            { path: 'faq/:uuid'        , component:() => import("@/components/pages/adminDashboard/support/faq/faqEditor")             ,  props : true,},
                            { path: 'updates'          , component:() => import("@/components/pages/adminDashboard/support/updates/DashUpdates")                           },
                            { path: 'updates/:uuid'    , component:() => import("@/components/pages/adminDashboard/support/updates/editUpdateDescription"), props : true  },
                            { path: "in_work"          , component:() => import("@/components/pages/adminDashboard/support/in_work/adminSupportInWork") ,  props : true   },
                            { path: "stats"            , component:() => import("@/components/pages/adminDashboard/support/adminSupportStatistics") ,  props : true   },
                            { path: "email_moderation" , component:() => import("@/modules/finemailer/components/AdminSupport/adminSupportEmailModeration.vue") ,  props : true   },
                        ]
                    },
                    // CRM
                    { path: "crm"              , component:() => import("@/components/pages/adminDashboard/crm/AdminCrm")                ,  props : true , children : [
                            { path: ""             , component:() => import("@/components/pages/adminDashboard/crm/adminCrmBusinesses") ,  props : true   },
                            { path: "reports"      , component:() => import("@/components/pages/adminDashboard/crm/adminCrmReports") ,  props : true   },
                            { path: "feedback"          , component:() => import("@/components/pages/adminDashboard/crm/adminCrmFeedback")                          },
                            { path: 'stats'    , component:() => import("@/components/pages/adminDashboard/crm/adminCrmStats")             ,  props : true,},
                        ]
                    },
                    { path: "converter"            , component:() => import("@/components/pages/adminDashboard/converter/AdminConverter")       },
                    { path: "templater"            , component:() => import("@/components/pages/adminDashboard/templater/DashTemplater")        },
                    { path: "translations"         , component:() => import("@/components/pages/adminDashboard/translations/DashTranslations")  },
                    { path: "domains"              , component:() => import("@/components/pages/adminDashboard/domains/DashDomains")                          },
                    { path: "businesses"           , component:() => import("@/components/pages/adminDashboard/businesses/DashAdminBusinesses")  ,  props : true },
                    { path: "referral"             , component:() => import("@/components/pages/adminDashboard/partners/DashAdminReferral")  ,  props : true , children : [
                            { path: ""             , component:() => import("@/components/pages/adminDashboard/partners/allReferrals")  ,  props : true },
                    ]},

                    { path: "payments"             , component:() => import("@/components/pages/adminDashboard/payments/DashPayments")   ,  props : true , children : [
                            { path: ""             , component:() => import("@/components/pages/adminDashboard/payments/businessesPayments")  ,  props : true },
                            { path: "new-clients"  , component:() => import("@/components/pages/adminDashboard/payments/newClients")  ,  props : true },
                            { path: "month-income"  , component:() => import("@/components/pages/adminDashboard/payments/monthIncome")  ,  props : true },
                            { path: "debt"  , component:() => import("@/components/pages/adminDashboard/payments/debtList")  ,  props : true },
                            { path: "deleted"  , component:() => import("@/components/pages/adminDashboard/payments/deletedClients")  ,  props : true },
                            { path: "deactivated"  , component:() => import("@/components/pages/adminDashboard/payments/deactivatedClients")  ,  props : true },
                    ]},
                    { path: "accounting"             , component:() => import("@/components/pages/adminDashboard/accounting/DashAdminAccouning")   ,  props : true , children : [
                            { path: ""             , component:() => import("@/components/pages/adminDashboard/accounting/accounitngPayments")  ,  props : true },
                            { path: "expenses"  , component:() => import("@/components/pages/adminDashboard/accounting/accounitngExpenses")  ,  props : true },
                            { path: "stats"  , component:() => import("@/components/pages/adminDashboard/accounting/accounitngStatistics")  ,  props : true },
                     ]},
                    { path: "expenses"  , component:() => import("@/components/pages/adminDashboard/accounting/DashAdminExpenses")  ,  props : true },


                    { path: "businesses/:uuid"     , component:() => import("@/components/pages/adminDashboard/businesses/crmAdminBusiness")     ,  props : true },
                    { path: "news"                 , component:() => import("@/components/pages/adminDashboard/news/DashboardNews")                       },
                    { path: 'news/:uuid'           , component:() => import("@/components/pages/adminDashboard/news/editNews")             ,  props : true,},
                    { path: "newsletter"           , component:() => import("@/components/pages/adminDashboard/newsletter/AdminNewsletter")      ,  props : true },
                    { path: "newsletter/:uuid"     , component:() => import("@/components/pages/adminDashboard/newsletter/viewAdminNewsletter")  ,  props : true },
                    { path: "documentation"        , component:() => import("@/components/pages/adminDashboard/documentation/DashDocumentation")                     },
                    { path: 'documentation/:uuid'  , component:() => import("@/components/pages/adminDashboard/documentation/documentationTopicEditor") , props : true},
                    { path: 'clientLeads'          , component:() => import("@/components/pages/adminDashboard/clientLeads/DashClientLeads")                        },
                    { path: 'tasks'                , component:() => import("@/components/pages/adminDashboard/tasks/AdminCrm")             , children : [
                            { path: ''             , component:() => import("@/components/pages/adminDashboard/tasks/allEventsTimeline")                      },
                            { path: 'projects'     , component:() => import("@/components/pages/adminDashboard/tasks/adminCrmProjects")                       },
                            { path: 'projects/:uuid',component:() => import("@/components/pages/adminDashboard/tasks/adminProject")         , props : true    },
                            { path: 'events/:type' , component:() => import("@/components/pages/adminDashboard/tasks/adminCrmEventsList") , props : true    },
                        ]
                    },
                    // partners
                    { path: "westudy_partner"             , component:() => import("@/components/pages/dashboard/partner/DashWestudyPartner")  ,  props : true , children : [
                            { path: ""             , component:() => import("@/components/pages/dashboard/partner/partnerDash")  ,  props : true },
                        ]},

                    // clients
                    { path: "business"             , component:() => import("@/components/pages/dashboard/business/DashBusiness")                        },
                    { path: "profile"              , component:() => import("@/components/pages/dashboard/profile/DashProfile")       },
                    { path: "profile/:id"          , component:() => import("@/components/pages/dashboard/profile/DashProfile")       },
                    { path: "users"                , component:() => import("@/components/pages/dashboard/users/DashUsers") },
                ]
        },
    ]
    return fineDashboardRoutes.concat(homePublicRoutes)

}



export default function getRouter(store) {
    loadModulesRoutes()
    checkMainDomain(store);
    return generateRoutes(store);

}