<template>
  <dash-page-new
      title="Automations"
      icon="mdi-auto-fix"
  >
    <ws-data-table
        :items="items"
        :headers="headers"
        :row-action="(event) => $router.push(businessDashLink(`automations/${event.uuid}`))"
    >


    </ws-data-table>
  </dash-page-new>
</template>

<style scoped>

</style>

<script>
export default {
  name: "DashAutomations",
  data() {
    return {
      items : [
        { name : 'Супер перко',
          uuid : 'GAVNO'
        }
      ]
    }
  },
  computed : {
    headers() {
      return [
        { text : 'Name' , value : 'name' },
        { text : 'Name' , value : 'name' }
      ]
    }
  }
}
</script>

